.black_bg {
  background: #000;
}

.nw_overlay {
  width: 100%;
  min-height: 100vh;
  height: auto;
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  background: var(--black);
  display: flex;
  flex-wrap: wrap;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
  }
}

#background_video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.vol_icon {
  width: 30px;
  height: 30px;
  text-indent: -999999px;
  position: fixed;
  left: 12%;
  bottom: 30px;
  cursor: pointer;

  &.mute {
    background: transparent url(../../../public/assets/images/mute-icon.svg) no-repeat center;
  }

  &.unmute {
    background: transparent url(../../../public/assets/images/unmute.svg) no-repeat center;
  }
}

.slider_o_cb {
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
  z-index: 9;

  @media (max-width: 767px) {
    padding-top: 15px;
    padding-bottom: 20px;
  }
}

.slide-element {
  display: flex;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  flex-direction: column;

  .slide-img {
    position: relative;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    overflow: hidden;
    min-height: 625px;

    @media (max-width: 1400px) {
      min-height: 450px;
    }

    @media (max-width: 767px) {
      min-height: 320px;
    }
  }

  .page-heading {
    font-family: var(--bs-font-main-title-family);
    color: #fff;
    font-size: 3.25rem;
    margin-bottom: 0;
    text-align: left;
    font-weight: 400;
    line-height: 1;

    span{
      font-size: 2.5rem;
      display: block;
      margin-bottom: 15px;
    }

    &.add_mb_spacer {
      margin-bottom: 50px;
    }
  }

  .slide-lead {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 200;
    margin-bottom: 0;

    strong {
      font-weight: 700;
    }
  }

  .arrow-link {
    display: inline-flex;
    padding: 10px 5px;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
    bottom: 20px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;

    i {
      display: block;
      margin-bottom: 2px;
      margin-left: 8px;
    }

    &:hover {
      i img {
        animation: headShake 3s linear infinite;
        -webkit-animation: headShake 3s linear infinite;
      }
    }
  }

  .slide-content {
    flex-grow: 1;
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 30px;
    padding-left: 50px;
  }

  /* .info-wrap { margin-left: 80px; } */
}

.slide-img {
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%;
}

.slide-content {
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
  max-width: 55%;
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
    -moz-transform: translateX(-6px) rotateY(-9deg);
    -ms-transform: translateX(-6px) rotateY(-9deg);
    -o-transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
    -moz-transform: translateX(5px) rotateY(7deg);
    -ms-transform: translateX(5px) rotateY(7deg);
    -o-transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
    -moz-transform: translateX(-3px) rotateY(-5deg);
    -ms-transform: translateX(-3px) rotateY(-5deg);
    -o-transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
    -moz-transform: translateX(2px) rotateY(3deg);
    -ms-transform: translateX(2px) rotateY(3deg);
    -o-transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

.scroll_mob_cb {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
  padding-bottom: 20px;
  overflow-x: auto;

  .slick-slider {
    width: 100%;
    margin-bottom: 50px;
  }
}

.m_view_d_cb {
  display: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.wrap_mobile {
  display: flex;
  margin: 0 auto;
  width: 100%;
  align-items: center;
}

@media (min-width: 1101px) and (max-width: 1400px) {

  /* body, html { font-size: 14px !important; } */
  .ol_i_grid .ol_img_con .title {
    font-size: 1.5rem;
    margin-top: 70px;
  }

  .m_view_d_cb {
    min-height: 450px !important;
  }
}

@media (max-width: 1400px) {
  .m_hide_d_cb {
    display: none !important;
  }

  .m_view_d_cb {
    width: 100%;
    min-height: 550px;
    display: block;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .slide-element {
    .slide-content {
      display: block;
      padding-left: 30px;
    }

    .slide-lead {
      font-size: 1.2rem;
      font-weight: 400;
    }

    .page-heading {
      font-size: 3rem;
      text-align: left;
    }
  }
}

@media (max-width: 767px) {
  .slide-element {
    display: block;

    .page-heading {
      font-size: 2.6rem;
      margin-bottom: 15px;

      span {
          font-size: 2rem;
          display: block;
          margin-bottom: 15px;
        }

      &.add_mb_spacer {
        margin-bottom: 15px;
      }
    }

    .slide-img,
    .slide-content {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
      padding-left: 0;
      padding-right: 0;

      .page-heading {
        text-align: center;
      }
    }

    .slide-lead {
      font-size: 1rem;
    }
  }

  .m_view_d_cb {
    min-height: 300px;
  }

  .slide-img {
    margin-left: 30px;
    margin-right: 30px;
  }

  .slider_center_mob {
    overflow-x: auto;
    height: 100%;
    display: flex;
    /* align-items: center; */
    align-items: flex-start;
    padding-bottom: 20px;

    .slider_center_mobile {
      width: 100%;
      margin-top: 30px;
    }
  }

  .scroll_mob_cb {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    height: 100%;
    position: relative;
    padding-bottom: 20px;
    overflow-x: auto;

    .slick-slider {
      width: 100%;
      margin-bottom: 50px;
    }
  }

  .mob_a_u_cb .slick-dots {
    /* bottom: 15px; */
  }

  .wrap_mobile {
    display: block !important;
  }

  #background_video {
    display: none;
  }
}

@media (max-width: 424px) {
  .m_view_d_cb {
    min-height: 200px;
  }

  .slide-element {
    .slide-content {
      padding-top: 20px;
    }

    .page-heading {
      font-size: 2rem;
      margin-bottom: 5px;

      span {
          font-size: 1.5rem;
          display: block;
          margin-bottom: 15px;
        }
    }
  }

  .book_online_btn .btn_link {
    height: 38px;
    background-size: 55% auto;
  }

  .slide-lead {
    font-size: 0.875rem !important;
  }

  .arrow-link,
  .book_online_btn .btn_link {
    font-size: 0.75rem !important;
  }
}

.main_content {
  font-size: 1.6rem;
  font-family: var(--bs-font-family);
  margin-bottom: 0px;
  margin-top: 15px;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 424px) {
  .main_content {
    font-size: 1rem;
    margin-top: 5px;
  }
}

.slider_box_cb {
  position: relative;
  width: 100%;
}

.grid_s_cb {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.grid_m_cb {
  flex: 0 0 auto;
  width: 33.33333333%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;

  .grid_inner_cb {
    position: relative;
    overflow: hidden;

    .grid_img_cb {
      position: relative;
      width: 100%;
      z-index: 2;
      min-height: 250px;

      &:before {
        content: "";
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
      }
    }

    &:hover .full_img {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
    }

    &:hover .title {
      // margin-top: 70px;
    }

    &:hover span {
      // bottom: 10px;

      i {
        display: block;
        margin-bottom: 2px;
        margin-left: 8px;

        img {
          animation: headShake 3s linear infinite;
          -webkit-animation: headShake 3s linear infinite;
        }
      }
    }
  }

  .full_img {
    width: 100%;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

  a {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 4;
    color: #fff;

    .desktop-content-container {
      display: block;
      width: 100%;
      padding: 15px 15px;

      background: rgba(0, 0, 0, 0.5);

      position: absolute;
      bottom: 0;

      .title {
        font-family: var(--bs-font-main-title-family);
        font-size: 1.4rem;
        line-height: 1;
        color: #fff;

        margin: 0;
        padding: 0;
      }

      .arrowIcon {
        display: contents;
        text-transform: uppercase;
        position: static;
        padding: 0;
      }
    }

    .mobile-content-container {
      display: none;
    }
  }

  .title {
    font-size: 1.6rem;
    font-family: var(--bs-font-main-title-family);
    color: #fff;
    line-height: 1;
    margin: 0;
    text-align: center;
    margin-top: 100px;
    padding: 10px 15px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

  span {
    display: flex;
    padding: 10px 15px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    position: absolute;
    bottom: 20px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;

    i {
      display: block;
      margin-bottom: 2px;
      margin-left: 8px;
    }
  }
}

.map_link_cb {
  color: #fff;
  text-align: center;
  font-size: 1rem;

  >span {
    display: inline-flex;
    position: relative;
    padding: 10px 15px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      background: url(../../../public/assets/images/location-icon.svg) no-repeat;
      background-size: contain;
      width: 12px;
      height: 16px;
      left: -2px;
    }

    i {
      display: block;
      margin-bottom: 2px;
      margin-left: 8px;
    }
  }

  &:hover span {
    i {
      display: block;
      margin-bottom: 2px;
      margin-left: 8px;

      img {
        animation: headShake 3s linear infinite;
        -webkit-animation: headShake 3s linear infinite;
      }
    }
  }
}

.learn_more_link_cb {
  color: #fff;
  text-align: center;

  span {
    display: inline-flex;
    position: relative;
    padding: 10px 15px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    cursor: pointer;

    i {
      display: block;
      margin-bottom: 2px;
      margin-left: 8px;
    }
  }

  &:hover span {
    i {
      display: block;
      margin-bottom: 2px;
      margin-left: 8px;

      img {
        animation: headShake 3s linear infinite;
        -webkit-animation: headShake 3s linear infinite;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .grid_m_cb {
    width: 26%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .grid_m_cb {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .grid_s_cb {
    display: block;
  }

  .grid_m_cb {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    background: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    .grid_inner_cb {
      display: flex;
      flex-wrap: wrap;

      .grid_img_cb {
        position: relative;
        width: 100px;
        min-height: 72px;
        overflow: hidden;

        &::before {
          display: none;
        }
      }

      &:hover {
        .title {
          margin-top: 0;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      position: relative;
      width: auto;
      height: auto;
      top: auto;
      left: auto;
      z-index: 1;
      color: #fff;
      flex: 1 0 0%;
      padding-right: 40px;

      &::after {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        background: url(../../../public/assets/images/arrow-icon.svg) no-repeat center;
        background-size: contain;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }

      .desktop-content-container {
        display: none;
      }

      .mobile-content-container {
        display: flex;
      }
    }

    .title {
      font-size: 1.6rem;
      font-family: var(--bs-font-main-title-family);
      color: #fff;
      line-height: 1;
      margin: 0;
      text-align: left;
      margin-top: 0px;
      padding: 14px 0 10px 25px;
    }

    span {
      display: none;
    }
  }
}

@media (max-width: 424px) {
  .map_link_cb span {
    font-size: 12px !important;
  }

  .learn_more_link_cb span {
    font-size: 12px !important;
  }

  .grid_m_cb {
    .title {
      font-size: 1.4rem;
    }
  }
}

@media (max-width: 375px) {
  .slider_center_mob {
    align-items: flex-start;
  }
}

@media (max-width: 1200px) {
  .nw_overlay {
    min-height: 1px;
  }
}